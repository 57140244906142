import { Helmet } from "react-helmet";
import hero from "../images/contactHero.jpg";
import ContactCard from "../components/ContactCard";

function Contact() {
  return (
    <>
      <Helmet>
        <title>Atlantis Submarine Adventures | Contact Us</title>
      </Helmet>
      <div className="container mw-100">
        <div className="row">
          <img src={hero} className="p-0 aboutHero" alt="hero" />
        </div>
      </div>
      <div className="container pt-5">
        <div
          className="row bg-white justify-content-center"
          style={{ color: "#03286B" }}
        >
          <div className="col-12 col-md-10 col-lg-6 mb-4">
            <p className="fs-2 text-center fw-bold mb-0">CONTACT US</p>
            <ContactCard
              title="Atlantis Submarines Waikiki"
              phone="1-800-381-0237"
              phone2="808-973-9811"
              email="ores@atlantisadventures.com"
              address1="Uber, Lyft, or car drop-off point for Atlantis Submarines Waikiki - 252 Paoa Pl, Honolulu, HI 96815"
              address2="Address for Atlantis Submarines Waikiki Kiosk: 2005 Kalia Rd, Honolulu, HI 96815. Ticket Office located at the Hilton Hawaiian Village beach front of Ali'i Tower, next to Tropics Bar and Grill."
              map1="https://maps.app.goo.gl/38pQx7UbAZeF8FYcA"
              map2="https://maps.app.goo.gl/k4zDq5jEwLPfSHBs8"
              hours="Open daily 8:30am-6pm"
            />
            <ContactCard
              title="Atlantis Submarines Maui"
              phone="1-800-381-0237"
              phone2="808-667-2224"
              address1="Temporarily closed due to Lahaina wildfire tragedy"
              // email="mres@atlantisadventures.com"
              // address1="658 Wharf St #11, Lahaina, HI 96761"
              // map1="https://goo.gl/maps/ud93zQRkFHCRY4Zt9"
              // hours="Open daily 7:30am-6pm"
            />
            <ContactCard
              title="Atlantis Submarines Kona"
              phone="1-800-381-0237"
              phone2="808-326-7827"
              email="kres@atlantisadventures.com"
              address1="75-5660 Palani Rd #304, Kailua-Kona, Hawaii 96740"
              map1="https://maps.app.goo.gl/YZD5E7TP5CHb9djN8"
              hours="Open daily 8am-5pm"
            />
            <ContactCard
              title="Majestic by Atlantis Cruises"
              phone="1-800-381-0237"
              phone2="808-973-1311"
              email="ores@atlantisadventures.com"
              address1="301 Aloha Tower Drive, Pier 6, Honolulu, HI 96813"
              map1="https://maps.app.goo.gl/tqGpnbNXhC9B8wKr6"
              hours="Open daily 8:30am-6pm"
              last
            />
          </div>
        </div>
      </div>
    </>
  );
}

export default Contact;
