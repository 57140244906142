const konaJobsList = [
  {
    title: "Guest Service Agent",
    description:
      "Our ideal candidate is a strong and energetic team player who enjoys continuous guest interaction, is kind to both guests and other staff, and has the ability to work in a fast-paced environment while maintaining a calm and friendly attitude. Previous sales experience and computer proficiency required. Responsibilities include reservations, guest check-in, and payment and voucher processing. This is a full-time position and will include weekends and holidays. Beautiful work environment! Pay rate is $20 per hour.",
  },
  {
    title: "Sales Manager",
    description:
      "Atlantis Adventures has an immediate opportunity for an experienced, results-oriented Sales Manager to join our management team in Kailua-Kona, Hawaii. Previous sales experience in the Hawaii hospitality (tour/travel) industry is required. We are seeking a seasoned and dependable team member with a positive and outgoing professional manner -- someone who is adaptable and dependable, has very good written and verbal communication skills, who takes initiative, and is focused on providing outstanding service. Position requires a fair amount of physical activity, including walking to numerous hotel concierge desks, escorting clients on submarine dives, participating in trade shows and expos, and conducting presentations. Management and maintenance of contractual arrangements with clients and agents is a key part of this position. This full-time position requires the ability to work some evenings and weekends and requires a valid driver’s license in good standing. A personal vehicle is required. The successful applicant will become a key participant in our management team, and represent the Big Island on our regional sales initiatives. Salary is $5,000/month.",
  },
  {
    title: "Submarine Pilots",
    description:
      "Position is responsible for driving the Atlantis tourist submarine while also functioning as a crew member. Must have previous Atlantis Submarines experience, have completed the Atlantis Submarines Pilot training program, and have an appropriate USCG license. Previous boating and SCUBA experience is helpful. A passion for excellent guest service is essential. The full-time flexible schedule will include weekends and holidays. Pay will be $30/hour and up, depending on experience. Supervisory positions are available with experience.",
  },
  {
    title: "Co-Pilot (Deckhand/Narrator)",
    description:
      "Position is responsible for delivering educational and entertaining marine narrations onboard our submarine and transport vessel while also functioning as a crew member. This entry level position helps prepare for entry into our Submersible Pilot training program. Previous boating and SCUBA experience is helpful. A passion for excellent guest service is desirable. The full-time flexible schedule will include weekends and holidays. Pay rate is $20 per hour.",
  },
];

export default konaJobsList;
