import { Helmet } from "react-helmet";
import hero from "../images/highlights.jpg";
import leftWaikiki from "../images/2 Waikiki.jpg";
import leftMaui from "../images/leftMaui.jpg";
import leftKona from "../images/2 Kona.jpg";
import leftMajestic from "../images/leftMajestic.jpg";
import LeftRight from "../components/LeftRight";

function Highlights() {
  return (
    <>
      <Helmet>
        <title>Atlantis Submarine Adventures | Tour Highlights</title>
      </Helmet>
      <div className="container mw-100">
        <div className="row position-relative">
          <img src={hero} className="p-0 aboutHero" alt="hero" />
          <div className="homeTextOverlayVideo fw-semibold">
            TOUR HIGHLIGHTS
          </div>
        </div>
        <LeftRight
          left={leftWaikiki}
          title="ATLANTIS SUBMARINES WAIKIKI"
          p1="Add deeper meaning to your island visit by diving over 100 feet in an authentic submarine. Our state-of-the-art submarines are Coast Guard approved and air-conditioned for your safety and comfort. Located within view of famous Leahi (Diamond Head), the Atlantis dive site is frequented by green turtles, sharks, stingrays, yellow tangs, eels, and many other species of underwater marine life."
          link="https://book.atlantisadventures.com/details/151625/atlantis-submarine-tour-in-waikiki/?promo=travelagent"
          button
          thingsReal
        />
        <LeftRight
          left={leftMaui}
          title="ATLANTIS SUBMARINES MAUI"
          p1="Atlantis Submarines Maui is proud to be recognized as one of the largest providers of tourist attractions on the Hawaiian Islands. Our spectacular island setting plays host to our flagship offering — the Atlantis Undersea Adventure. Featuring the most technologically advanced vessels of their kind, these absolutely real submarines carry up to 48 passengers to depths of 100 feet and beyond."
          changeBG
          link="https://book.atlantisadventures.com/details/151037/atlantis-submarine-tour-maui/?promo=travelagent"
          button
          thingsReal
        />
        <LeftRight
          left={leftKona}
          title="ATLANTIS SUBMARINES KONA"
          p1="Allow Atlantis Submarines to show you the other 96% of Kona you can't see any other way. Beneath the surface of the ocean, you'll have the chance to see Kona's marine mysteries including 25 acres of stunning natural reef and all of its many inhabitants. Join us for one of Kona's most unique oceanic adventures!"
          link="https://book.atlantisadventures.com/details/154563/atlantis-submarine-tour-kona?promo=travelagent"
          button
          thingsReal
        />
        <LeftRight
          left={leftMajestic}
          title="MAJESTIC BY ATLANTIS CRUISES"
          p1="Step out onto Majestic's open-air decks and take the opportunity to simply enjoy Hawaii's beautiful sunsets and skylines. The Majestic features two decks that offer the space to take in unobscured panoramic views and lovely breezes. Join us on our Waikiki Sunset Cocktail Cruise where we offer the only full service, open seating bar and lounge sailing Oahu's waters. Or check out our seasonal whale watch cruise to spot Hawaii's humpback whales as they breed, calve and nurse their young in the islands during the winter/spring months."
          link="https://book.majestichawaii.com/details/204130/waikiki-sunset-cocktail-cruise/?promo=travelagent"
          changeBG
          button
          thingsReal
        />
      </div>
    </>
  );
}

export default Highlights;
