const mauiJobsList = [
  // {
  //   title: "Guest Service Agent",
  //   description:
  //     "Our ideal candidate has a confident, friendly, and outgoing personality — someone who enjoys continuous guest interaction and has the ability to work in a fast-paced environment while maintaining a calm and friendly attitude. Our Agents must be kind and considerate to guests and fellow employees and must have good computer skills. Previous sales experience helpful. Responsibilities include phone reservations, internet reservations, guest check-in, merchandise sales, and payment and voucher processing. The full-time flexible schedule includes weekends and holidays. Shifts will be between 7:30 am - 6:00 pm.",
  // },
  // {
  //   title: "Co-Pilot (Deckhand/Narrator)",
  //   description:
  //     "Position is responsible for delivering educational and entertaining marine narrations onboard our submarine and transport vessel while also functioning as a crew member. This entry level position helps prepare for entry into our Submersible Pilot training program. Previous boating and SCUBA experience helpful. Coast Guard license and mechanical skills a plus. A passion for excellent guest service is desirable.",
  // },
  // {
  //   title: "Maintenance Technician",
  //   description:
  //     "Position requires previous marine maintenance experience. Marine electronics experience and SCUBA certification is helpful. The full-time flexible schedule will include weekends and holidays.",
  // },
  // {
  //   title: "Diesel Mechanic or ASE Certified Mechanic",
  //   description:
  //     "Position requires previous experience as an ASE certified mechanic or as a Diesel Mechanic. Marine electronics experience and SCUBA certification is helpful. The full-time flexible schedule will include weekends and holidays.",
  // },
];

export default mauiJobsList;
