export default function VideoFrame({ video, overlay, img }) {
  return (
    <div className="row position-relative heightVideo">
      {/* <video
        src={video}
        autoPlay
        loop
        muted
        playsInline
        className="p-0 homeVidSizeNew"
      /> */}

      <div
        className="p-0"
        dangerouslySetInnerHTML={{
          __html: `<video class="homeVidSizeNew" autoplay loop muted playsinline preload="auto">
      <source src=${video} type="video/mp4" />
      Your browser does not support the video.
</video>`,
        }}
      />

      {overlay && !img && (
        <div className="homeTextOverlayVideo">EXPLORE OUR HAWAII</div>
      )}
      {!overlay && img && (
        <img
          src={img}
          className="homeTextOverlayVideo subsOverlay"
          alt="locations logo"
        />
      )}
    </div>
  );
}
