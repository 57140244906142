export default function JobsCard({ list, place, addJob }) {
  if (list.length === 0) {
    return (
      <div className="jobs">
        <p className="fs-3 mb-4 fw-bold">{place}</p>
        <p className="mb-0">No jobs available</p>
      </div>
    );
  }

  return (
    <div className="jobs">
      <p className="fs-3 mb-4 fw-bold">{place}</p>
      {list.map((job, i, row) => {
        return (
          <div key={job.title}>
            <p className="fs-5 mb-1 fw-bold">{job.title}</p>
            <p className={i === row.length - 1 ? "mb-0" : ""}>
              {job.description} {newFunction(job, addJob, place)}
            </p>
          </div>
        );
      })}
    </div>
  );

  function newFunction(job, addJob, place) {
    return (
      <a
        href="#0"
        onClick={(event) => {
          event.preventDefault();
          const target = document.getElementById("0");
          if (target) {
            target.scrollIntoView({ behavior: "smooth" });
          }
          addJob(`${place}: ${job.title}`);
        }}
      >
        Add to form
      </a>
    );
  }
}
